import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import {
  Layout,
  SEO,
  BannerStandard,
  ContentServiceDescription,
  ContentServiceTargetAudience,
  ContentServiceModule,
  ContentServiceSignUp,
  ContainerBackgroundWhite,
} from "../../components";

import { ServicesData } from "../../components/data";
import { Colors } from "../../components/constants";
import { ListCheckMarks } from "../../components/lists";
import { InternalLinkTypes } from "../../components/links";

const Fcaspays = () => {
  const service = ServicesData["fcaspays"];
  const data = useStaticQuery(graphql`
    query {
      ImgFcasLeft: file(
        relativePath: { eq: "services/fcaspays/fcas_left.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgFinance: file(
        relativePath: { eq: "services/fcaspays/financial-table.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgPhysical: file(
        relativePath: { eq: "services/fcaspays/physical-chart.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgPerformance: file(
        relativePath: { eq: "services/fcaspays/performance.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgFcasFront: file(
        relativePath: { eq: "services/fcaspays/fcas_front.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const ImgFcasLeft = ({ style }) => {
    return <Img fluid={data.ImgFcasLeft.childImageSharp.fluid} style={style} />;
  };

  const ImgFinance = ({ style }) => {
    return <Img fluid={data.ImgFinance.childImageSharp.fluid} style={style} />;
  };

  const ImgPhysical = ({ style }) => {
    return <Img fluid={data.ImgPhysical.childImageSharp.fluid} style={style} />;
  };

  const ImgPerformance = ({ style }) => {
    return (
      <Img fluid={data.ImgPerformance.childImageSharp.fluid} style={style} />
    );
  };

  const ImgFcasFront = ({ style }) => {
    return (
      <Img fluid={data.ImgFcasFront.childImageSharp.fluid} style={style} />
    );
  };

  return (
    <Layout pageLink={InternalLinkTypes.ServicesFcasPays}>
      <SEO
        title={"FCAS pays"}
        description={
          "Our FCASpays platform provides three different pages to inspect the finance reports, performance metrics and enhanced physical data related to regulation FCAS."
        }
      />
      <BannerStandard title={service.title} subTitle={service.description} />
      <ContentServiceDescription
        image={<ImgFcasLeft style={{ maxWidth: "450px", margin: "auto" }} />}
        description={
          <div>
            <h2
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.17rem",
              }}
            >
              Regulation 4 Second Causer Pays and Frequency Indicator Data
            </h2>
            <h3
              style={{
                color: Colors.LIGHT_BLUE,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Our FCASpays platform provides three different pages to inspect
              the finance reports, performance metrics and enhanced physical
              data related to regulation FCAS.
            </h3>
            <ListCheckMarks
              items={[
                "regulation FCAS performance metrics using the PJM market definition",
                "beautifully charted 4 second causer pays and FI enhanced data",
                "causer pays contribution factor reconciliation",
                "breakdown of gross margin components in providing regulation FCAS",
              ]}
            />
            <h3
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              * Visualising unit instructions, response and frequency behaviour
              will allow you to monitor the efficacy in the provisioning of
              regulation FCAS and identify conditions when your regulation
              services can be better priced.
            </h3>
            <p>
              FCASpays uses enriched 4 second causer pays data, frequency
              indicator (FI) data and other NEM market data.
            </p>
          </div>
        }
      />
      <ContentServiceTargetAudience
        freeTrialLink={service.freeTrialLink}
        targetAudienceList={service.targetAudienceList}
      />

      <ContentServiceModule
        descriptionList={[
          "Breakdown of regulation FCAS gross margin",
          "Helps create a great business case for automating FCAS bids",
          "Understand the additional costs and benefits of regulation FCAS enablement",
          "See how providing regulation FCAS when energy prices are either high or negative can cost a lot of money",
          "See when you are enabled at a loss and where there are untapped opportunities",
          "Aggregate time from 5 minutes to yearly and units to station, portfolio and region level",
          "Includes enabled and non-enabled causer pays contribution factors",
        ]}
        title={"Finance reports"}
        image={<ImgFinance />}
        isBackgroundWhite={false}
        isImageRightAligned={false}
      />
      <ContentServiceModule
        descriptionList={[
          "Modelled using the USA's PJM market performance metric definitions",
          "Our AGC signal approximation enables you to quickly identify poor performance of both your units and your competitors",
          "Identify free riders!",
          "Reduce liabilities",
          "Play your part to improve frequency by understanding contributions to frequency control",
          "Consume our data to support regulatory views",
        ]}
        title={"Performance metrics"}
        image={<ImgPerformance />}
        isBackgroundWhite={true}
        isImageRightAligned={true}
      />
      <ContentServiceModule
        descriptionList={[
          "Beautiful charting of 4 second causer pays and frequency indicator data",
          "Clear visualisation of unit behaviour as a function of frequency and our AGC signal estimate",
          "Includes enriched data such as 4 second trajectory (linear extrapolation from previous dispatch target to current dispatch target)",
        ]}
        title={"Enhanced physical data"}
        image={<ImgPhysical />}
        isBackgroundWhite={false}
        isImageRightAligned={false}
      />

      <ContentServiceSignUp
        title={service.title}
        freeTrialLink={service.freeTrialLink}
        videoLink={service.watchVideoLink}
      />
      <ContainerBackgroundWhite style={{ padding: "1% 20%" }}>
        <ImgFcasFront />
      </ContainerBackgroundWhite>
    </Layout>
  );
};

export default Fcaspays;
